var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-5"},[_c('loading-flux',{attrs:{"value":_vm.loading}}),_vm._m(0),_c('br'),_c('v-data-table',{staticClass:"px-2 elevation-4 w-75 pt-3",attrs:{"headers":_vm.tableHeaders,"items":_vm.usersInWhiteList,"hide-default-footer":"","loading-text":"Cargando usuarios","item-key":"id","no-data-text":"Sin usuarios"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-btn',{attrs:{"small":"","color":"blue darken-3 text-white float-right mx-3"},on:{"click":_vm.openModalAddUserWhitelist}},[_vm._v(" Agregar Usuario ")])]},proxy:true},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-left my-0"},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-start"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.deleteUserWhitelist(item.email)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar usuario")])])],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"600px"},on:{"keydown":_vm.closeModalAddUserWhitelist,"click:outside":_vm.closeModalAddUserWhitelist},model:{value:(_vm.dialogAddUserWhitelist),callback:function ($$v) {_vm.dialogAddUserWhitelist=$$v},expression:"dialogAddUserWhitelist"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h6"},[_vm._v("Añadir usuario a la lista")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.addUserWhitelist.apply(null, arguments)}}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Correo electrónico","type":"text","clearable":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loading,"color":"red darken-1","outlined":"","text":""},on:{"click":_vm.closeModalAddUserWhitelist}},[_c('small',[_vm._v(" Cerrar ")])]),_c('v-btn',{attrs:{"color":"blue darken-3","disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.addUserWhitelist}},[_c('small',{staticClass:"text-white"},[_vm._v(" Añadir Usuario ")])])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h5',{},[_vm._v(" Listado de Usuarios (Whitelist) con acceso a la plataforma en Mantenimiento ")])])}]

export { render, staticRenderFns }