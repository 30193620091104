<template>
  <section class="mt-5 pt-5">
    <loading-flux :value="loading"></loading-flux>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="">
        Configurar estilo de la sección de mantenimiento
      </h5>
    </div>
    <v-form @submit.prevent="updateMaintenanceInfo">
      <v-row align="center">
        <v-col cols="12" md="12" sm="12" class="pb-0 pt-5 mt-5">
          <v-textarea
            v-model="form.title"
            label="Título"
            clearable
            dense
            class="w-75"
            rows="2"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="12" sm="12" class="pb-0 mt-5">
          <v-textarea
            v-model="form.description"
            label="Descripción"
            clearable
            rows="2"
            class="w-75"
            dense
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="12" sm="12" class="mt-5">
          <section class="w-75">
            <v-btn
              @click="updateMaintenanceInfo"
              :disabled="!form.title && !form.description"
              small
              color="blue darken-3 text-white"
              class="float-right"
            >
              Actualizar datos
            </v-btn>
          </section>
        </v-col>
        <v-col cols="12" md="12" sm="12" class="pb-0 pt-5 mt-5">
          <v-row align="start" align-content="start">
            <v-col cols="12" md="6" sm="12">
              <v-file-input
                label="Pulsa aquí para cambiar la imagen de fondo actual"
                v-model="form.file"
                @change="updateBackgroundImageMaintenance"
              >
              </v-file-input>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <section class="mx-5">
                <label for="">
                  Imagen de fondo actual
                </label>
                <br />
                <b-img
                  width="250"
                  fluid
                  thumbnail
                  v-if="getCurrentBackgroundImageMaintenance"
                  :src="getCurrentBackgroundImageMaintenance.url"
                >
                </b-img>
                <span v-else>
                  Sin imagen configurada actualmente
                </span>
              </section>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import swal2 from "sweetalert2";

export default {
  mounted() {
    this.getBackgroundImagesMaintenance();
    this.getAndSetMaintenanceInfo();
  },
  data() {
    return {
      loading: false,
      form: {
        title: "",
        description: "",
        file: null,
      },
    };
  },
  methods: {
    ...mapActions({
      getBackgroundImagesMaintenance: "auth/getBackgroundImagesMaintenance",
      sendUpdateBackgroundImageMaintenance:
        "auth/updateBackgroundImageMaintenance",
      getMaintenanceInfo: "auth/getMaintenanceInfo",
      sendUpdateMaintenanceInfo: "auth/updateMaintenanceInfo",
    }),
    async getAndSetMaintenanceInfo() {
      const data = await this.getMaintenanceInfo();
      if (data.length) {
        this.form.title = data.find(
          (item) => item.info_type == "title"
        )?.description;
        this.form.description = data.find(
          (item) => item.info_type == "description"
        )?.description;
      }
    },
    async updateBackgroundImageMaintenance() {
      if (this.form.file) {
        this.loading = true;
        const resp = await this.sendUpdateBackgroundImageMaintenance({
          user: this.user.email,
          file: this.form.file,
        });
        if (resp.status == 200) {
          swal2.fire({
            icon: "success",
            title: "Excelente",
            text: "Imagen de fondo modificada correctamente",
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 3000,
            timerProgressBar: true,
          });
          this.form.file = null;
        } else {
          swal2.fire({
            icon: "error",
            title: "Error",
            text: "Ha ocurrido un error, intentalo nuevamente",
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 3000,
            timerProgressBar: true,
          });
        }
        this.loading = false;
      }
    },
    async updateMaintenanceInfo() {
      this.loading = true;
      const resp = await this.sendUpdateMaintenanceInfo({
        title: this.form.title ? this.form.title : undefined,
        description: this.form.description ? this.form.description : undefined,
      });
      if (resp.status == 200) {
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: "Datos actualizados correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
        this.form.file = null;
      } else {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error, intentalo nuevamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.loading = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      backgroundImagesMaintenance: "auth/backgroundImagesMaintenance",
    }),
    getCurrentBackgroundImageMaintenance() {
      if (
        this.backgroundImagesMaintenance &&
        this.backgroundImagesMaintenance.length
      ) {
        return this.backgroundImagesMaintenance[
          this.backgroundImagesMaintenance.length - 1
        ];
      }
      return null;
    },
  },
};
</script>

<style></style>
