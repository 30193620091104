<template>
  <section class="mt-5">
    <loading-flux :value="loading"></loading-flux>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="">
        Listado de Usuarios (Whitelist) con acceso a la plataforma en
        Mantenimiento
      </h5>
    </div>
    <br />
    <v-data-table
      class="px-2 elevation-4 w-75 pt-3"
      :headers="tableHeaders"
      :items="usersInWhiteList"
      hide-default-footer
      loading-text="Cargando usuarios"
      item-key="id"
      no-data-text="Sin usuarios"
    >
      <template v-slot:top>
        <v-btn
          @click="openModalAddUserWhitelist"
          small
          color="blue darken-3 text-white float-right mx-3"
        >
          Agregar Usuario
        </v-btn>
      </template>
      <template v-slot:item.email="{ item }">
        <p class="text-left my-0">
          {{ item.email }}
        </p>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-content-start">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                icon
                small
                color="red"
                v-bind="attrs"
                v-on="on"
                @click="deleteUserWhitelist(item.email)"
              >
                <v-icon>
                  mdi-delete-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Eliminar usuario</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogAddUserWhitelist"
      max-width="600px"
      @keydown="closeModalAddUserWhitelist"
      @click:outside="closeModalAddUserWhitelist"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Añadir usuario a la lista</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent="addUserWhitelist">
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="form.email"
                  label="Correo electrónico"
                  type="text"
                  clearable
                ></v-text-field>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="closeModalAddUserWhitelist"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
          <v-btn
            color="blue darken-3"
            @click="addUserWhitelist"
            :disabled="loading"
            :loading="loading"
          >
            <small class="text-white">
              Añadir Usuario
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
export default {
  data() {
    return {
      dialogAddUserWhitelist: false,
      loading: false,
      form: {
        email: "",
      },
      tableHeaders: [
        {
          text: "Acción",
          align: "start",
          sortable: false,
          value: "actions",
        },
        {
          text: "Correo electrónico",
          align: "start",
          sortable: false,
          value: "email",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      sendDeleteUserWhitelist: "auth/deleteUserWhitelist",
      sendAddUserWhitelist: "auth/addUserWhitelist",
    }),
    async deleteUserWhitelist(email) {
      let self = this;
      this.$swal
        .fire({
          icon: "error",
          title: `Deseas eliminar este usuario: ${email} ?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: `Cancelar`,
          confirmButtonColor: "red",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            const resp = await this.sendDeleteUserWhitelist(email);
            if (resp.status === 200) {
              self.$swal.fire({
                icon: "success",
                title: "Excelente",
                text: "Usuario eliminado correctamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            this.loading = false;
          }
        });
    },
    async addUserWhitelist() {
      if (!this.form.email) {
        return this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Debes ingresar el correo electrónico",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.loading = true;
      const resp = await this.sendAddUserWhitelist(this.form.email);
      if (resp.status === 200) {
        this.$swal.fire({
          icon: "success",
          title: "Excelente",
          text: "Usuario agregado correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.loading = false;
      this.closeModalAddUserWhitelist();
    },
    openModalAddUserWhitelist() {
      this.form.email = "";
      this.dialogAddUserWhitelist = true;
    },
    closeModalAddUserWhitelist($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogAddUserWhitelist = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      usersInWhiteList: "auth/usersInWhiteList",
    }),
  },
};
</script>

<style></style>
