<template>
  <v-container class="mt-5 pb-5 mb-5">
    <loading-flux :value="loading"></loading-flux>
    <v-row class="mt-5">
      <v-col cols="12" md="12" sm="12">
        <section class="d-flex flex-column flex-sm-row justify-content-between">
          <h2 class="ml-1">
            Configuración
          </h2>
          <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
        </section>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-tabs vertical>
          <v-tab class="mt-1">
            <v-icon left>
              mdi-alert-outline
            </v-icon>
            <small class="mx-2">
              Modo Mantenimiento
            </small>
          </v-tab>
          <v-tab class="mt-1">
            <v-icon left>
              mdi-message-outline
            </v-icon>
            <small class="mx-2">
              Banner de aviso
            </small>
          </v-tab>
          <v-tab-item>
            <v-card flat class="px-5">
              <v-card-text>
                <h5 class="">
                  Activar / Desactivar Modo Mantenimiento
                </h5>
                <div class="d-flex align-items-center">
                  <v-switch
                    @change="updateMaintenanceMode"
                    class="mt-2"
                    inset
                    v-model="maintenanceMode"
                  >
                    <template v-slot:label>
                      <small class="mt-2">
                        {{
                          maintenanceMode
                            ? "Mantenimiento Activado"
                            : "Mantenimiento Desactivado"
                        }}
                      </small>
                    </template>
                  </v-switch>
                </div>
                <br />
                <br />
                <table-users-maintenance-whitelist class="pb-5">
                </table-users-maintenance-whitelist>
                <br />
                <br />
                <form-background-maintenance> </form-background-maintenance>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat class="px-5">
              <v-card-text>
                <banner-text> </banner-text>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import appConfig from "@/app.config";
import { mapActions } from "vuex";
import swal2 from "sweetalert2";
import TableUsersMaintenanceWhitelist from "./components/TableUsersMaintenanceWhitelist.vue";
import FormBackgroundMaintenance from "./components/FormBackgroundMaintenance.vue";
import BannerText from "./components/BannerText.vue";
export default {
  components: {
    TableUsersMaintenanceWhitelist,
    FormBackgroundMaintenance,
    BannerText,
  },
  page: {
    title: "Configuración",
    meta: [{ name: "description", content: appConfig.description }],
  },
  async mounted() {
    await this.getAndSetMaintenanceMode();
  },
  data() {
    return {
      loading: false,
      maintenanceMode: false,
      breadcrumb: [
        {
          text: "Configuración",
          disabled: false,
          href: "/",
        },
        {
          text: "Inicio",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getMaintenanceMode: "auth/maintenance",
      sendUpdateMaintenanceMode: "auth/updateMaintenanceMode",
    }),
    async getAndSetMaintenanceMode() {
      const resp = await this.getMaintenanceMode();
      this.maintenanceMode = resp.mode;
    },
    async updateMaintenanceMode() {
      this.loading = true;
      const resp = await this.sendUpdateMaintenanceMode();
      if (resp.status == 200) {
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: `Modo Mantenimiento ${
            !this.maintenanceMode ? "Desactivado" : "Activado"
          }`,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error, intentalo nuevamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.loading = false;
    },
  },
};
</script>
