<template>
  <section class="">
    <loading-flux :value="loading"></loading-flux>
    <h5 class="">
      Activar / Desactivar Banner
    </h5>
    <div class="">
      <v-switch
        :disabled="!currentBannerAlertInfoId"
        @change="updateBannerAlertInfoStatus"
        class="mt-2 pb-0"
        inset
        v-model="bannerActive"
      >
        <template v-slot:label>
          <small class="mt-2">
            {{ bannerActive ? "Banner Activado" : "Banner Desactivado" }}
          </small>
        </template>
      </v-switch>
      <small class="text-danger" v-if="!currentBannerAlertInfoId">
        Debes configurar un texto del banner para poder activarlo
      </small>
    </div>
    <div class="d-flex align-items-center justify-content-between mt-5 pt-5">
      <h5 class="">
        Texto para banner de aviso de la plataforma
      </h5>
    </div>
    <v-form @submit.prevent="updateBannerAlertInfo">
      <v-row align="center">
        <v-col cols="12" md="12" sm="12" class="pb-0 mt-5">
          <v-textarea
            v-model="form.banner_text"
            label="Ingresa aqui el texto"
            clearable
            rows="2"
            class="w-75"
            dense
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="12" sm="12" class="mt-5">
          <section class="w-75">
            <v-btn
              @click="updateBannerAlertInfo"
              :disabled="!form.banner_text"
              small
              color="blue darken-3 text-white"
              class="float-right"
            >
              Actualizar datos
            </v-btn>
          </section>
        </v-col>
      </v-row>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import swal2 from "sweetalert2";

export default {
  data() {
    return {
      loading: false,
      currentBannerAlertInfoId: null,
      bannerActive: false,
      form: {
        banner_text: "",
      },
    };
  },
  methods: {
    ...mapActions({
      sendUpdateBannerAlertInfoStatus: "auth/updateBannerAlertInfoStatus",
      sendUpdateOrCreateBannerAlertInfo: "auth/updateOrCreateBannerAlertInfo",
    }),
    async updateBannerAlertInfoStatus() {
      this.loading = true;
      const resp = await this.sendUpdateBannerAlertInfoStatus({
        id: this.currentBannerAlertInfoId,
      });
      if (resp.status == 200) {
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: `Banner de Aviso ${
            !this.bannerActive ? "Desactivado" : "Activado"
          }`,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error, intentalo nuevamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.loading = false;
    },
    async updateBannerAlertInfo() {
      this.loading = true;
      const resp = await this.sendUpdateOrCreateBannerAlertInfo({
        id: this.currentBannerAlertInfoId
          ? this.currentBannerAlertInfoId
          : undefined,
        description: this.currentBannerAlertInfoId
          ? undefined
          : this.form.banner_text,
        info: this.currentBannerAlertInfoId ? this.form.banner_text : undefined,
        user: this.user.email,
      });
      if (resp.status == 200) {
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: "Texto del banner actualizado correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
        this.currentBannerAlertInfoId = resp.data.id;
      } else {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error, intentalo nuevamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.loading = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      bannerAlertInfo: "auth/bannerAlertInfo",
    }),
  },
  watch: {
    bannerAlertInfo: {
      immediate: true,
      handler: function(newInfo) {
        if (newInfo) {
          this.currentBannerAlertInfoId = newInfo.id;
          this.bannerActive = newInfo.active;
          this.form.banner_text = newInfo.text;
        }
      },
    },
  },
};
</script>

<style></style>
